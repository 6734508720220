import { createStore, applyMiddleware, compose } from 'redux'
import reducers from '../reducers'
import ReduxThunk from 'redux-thunk'
import initialState from '../reducers/initialState'

const enhancers = []
const middleware = [ReduxThunk]

if (process.env.NODE_ENV === 'development') {
	if (typeof window !== 'undefined') {
		const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
		if (typeof devToolsExtension === 'function') {
			enhancers.push(devToolsExtension())
		}
	}
}
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

export default () => createStore(reducers, initialState, composedEnhancers)
