import { LOADING_STATE_IDLE } from '../constants/actionTypes'

export default {
	users: {
		isLoading: false,
		user: undefined,
	},
	posts: {
		isLoading: false,
		isUpdating: false,
		posts: [],
		post: undefined,
	},
	layout: {
		showModal: false,
		modalInfo: {
			title: '',
			componentName: undefined,
		},
	},
	hashtags: {
		listState: LOADING_STATE_IDLE,
		updateState: LOADING_STATE_IDLE,
		hashtags: [],
	},
}
