import initialState from './initialState'
import { SHOW_MODAL, CLOSE_MODAL } from '../constants/actionTypes'

export default function layoutReducer(state = initialState.layout, action) {
	switch (action.type) {
		case SHOW_MODAL:
			const { title, ...additionalProps } = action.payload.props
			return {
				...state,
				showModal: true,
				modalInfo: {
					title,
					additionalProps,
					componentName: action.payload.componentName,

				},
			}
		case CLOSE_MODAL:
			return {
				...state,
				...initialState.layout,
			}
		default:
			return state
	}
}
