/**
 * Implement Gatsby's Browser APIs in this file. 
 * 
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './src/utils/aws-exports';
import wrapWithProvider from "./wrap-with-provider"

Amplify.configure(awsconfig);
export const wrapRootElement = wrapWithProvider
