import initialState from './initialState'
import {
	FETCH_POSTS_REQUEST,
	FETCH_POSTS_SUCCESS,
	FETCH_POSTS_FAILURE,
	UPDATE_POST_REQUEST,
	UPDATE_POST_SUCCESS,
	UPDATE_POST_FAILURE,
	FILTER_REVIEWED_POSTS,
} from '../constants/actionTypes'

export default function postsReducer(state = initialState.posts, action) {
	switch (action.type) {
		case FETCH_POSTS_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case FETCH_POSTS_SUCCESS:
			return {
				...state,
				posts: action.payload.cpcs,
				isLoading: false,
			}
		case FETCH_POSTS_FAILURE:
			return {
				...state,
				isLoading: false,
			}
		case UPDATE_POST_REQUEST:
			return {
				...state,
				isUpdating: true,
			}
		case UPDATE_POST_SUCCESS: {
			const id = parseInt(action.payload.cpc.id)
			const posts = state.posts.map(post => {
				if (post.id === id) {
					return { ...post, ...action.payload.cpc }
				}
				return post
			})
			return {
				...state,
				isUpdating: false,
				posts,
			}
		}
		case UPDATE_POST_FAILURE:
			return {
				...state,
				isUpdating: false,
			}
		case FILTER_REVIEWED_POSTS: {
			const posts = state.posts.filter(post => !parseInt(post.is_reviewed))
			return {
				...state,
				posts,
			}
		}
		default:
			return state
	}
}
