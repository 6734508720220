import initialState from './initialState'
import {
	FETCH_HASHTAGS_REQUEST,
	FETCH_HASHTAGS_SUCCESS,
	FETCH_HASHTAGS_FAILURE,
	SORT_HASHTAGS,
	UPDATE_HASHTAG_REQUEST,
	UPDATE_HASHTAG_SUCCESS,
	UPDATE_HASHTAG_FAILURE,
	LOADING_STATE_LOADING,
	LOADING_STATE_SUCCESS,
	LOADING_STATE_FAILURE,
} from '../constants/actionTypes'

export default function hashtagsReducer(state = initialState.hashtags, action) {
	switch (action.type) {
		case FETCH_HASHTAGS_REQUEST:
			return {
				...state,
				listState: LOADING_STATE_LOADING,
			}
		case FETCH_HASHTAGS_SUCCESS:
			return {
				...state,
				listState: LOADING_STATE_SUCCESS,
				hashtags: action.payload.tags,
			}
		case FETCH_HASHTAGS_FAILURE:
			return {
				...state,
				listState: LOADING_STATE_FAILURE,
			}
		case SORT_HASHTAGS: {
			const { oldIndex, newIndex } = action.payload
			const tag = state.hashtags[oldIndex]
			const sortedHashtahs = [...state.hashtags]
			sortedHashtahs.splice(oldIndex, 1)
			sortedHashtahs.splice(newIndex, 0, tag)
			return {
				...state,
				hashtags: sortedHashtahs,
			}
		}
		case UPDATE_HASHTAG_REQUEST: {
			return {
				...state,
				updateState: LOADING_STATE_LOADING,
			}
		}
		case UPDATE_HASHTAG_SUCCESS: {
			const id = action.payload.tag.id
			const hashtags = state.hashtags.map(tag => {
				if (tag.id === id) {
					return { ...tag, ...action.payload.tag }
				}
				return tag
			})
			return {
				...state,
				updateState: LOADING_STATE_SUCCESS,
				hashtags,
			}
		}
		case UPDATE_HASHTAG_FAILURE:
			return {
				...state,
				updateState: LOADING_STATE_FAILURE,
			}
		default:
			return state
	}
}
