import initialState from './initialState'
import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../constants/actionTypes'

export default function userReducer(state = initialState.users, action) {
	switch (action.type) {
		case FETCH_USER_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case FETCH_USER_SUCCESS:
			return {
				...state,
				user: action.payload.user,
				isLoading: false,
			}
		case FETCH_USER_FAILURE:
			return {
				...state,
				isLoading: false,
			}
		default:
			return state
	}
}
