// Users
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

// Posts
export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST'
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS'
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE'

// Update Post
export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE'

// Hashtags
export const FETCH_HASHTAGS_REQUEST = 'FETCH_HASHTAGS_REQUEST'
export const FETCH_HASHTAGS_SUCCESS = 'FETCH_HASHTAGS_SUCCESS'
export const FETCH_HASHTAGS_FAILURE = 'FETCH_HASHTAGS_FAILURE'
// Sort Hashtags
export const SORT_HASHTAGS = 'SORT_HASHTAGS'
// Update Hashtags
export const UPDATE_HASHTAG_REQUEST = 'UPDATE_HASHTAG_REQUEST'
export const UPDATE_HASHTAG_SUCCESS = 'UPDATE_HASHTAG_SUCCESS'
export const UPDATE_HASHTAG_FAILURE = 'UPDATE_HASHTAG_FAILURE'

// Filter Reviewed Post
export const FILTER_REVIEWED_POSTS = 'FILTER_REVIEWED_POSTS'

// Layout
export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

// Loading States
export const LOADING_STATE_IDLE = 'IDLE'
export const LOADING_STATE_LOADING = 'LOADING'
export const LOADING_STATE_SUCCESS = 'SUCCESS'
export const LOADING_STATE_FAILURE = 'FAILURE'
